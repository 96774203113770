// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { CIPAPIConfig } from "src/app/shared/datasource/interfaces/CIP/cip-general.interface";
import { genericVars } from "./generic-variables";

export const environment = {
  appRegistrationPrefix: "T",
  azuretenant:"a7710ec2-1dbb-4980-9379-65fee8028a40",
  azureappid:"326fb894-0701-4074-a9fc-02e790b5bb03",
  consoleLogs: false,
  generic: genericVars,
  jeannieApiURL: 'https://app-connect-test.sdc.computacenter.de',
  jeannieEnvironment: "Test",
  production: false,
  redirectURl:"https://ux-portal-test.sdc.computacenter.de",
  toolbartitle: "User Experience Portal - Test",
  welcomemessage: "Welcome to the User Experience Portal - Testing",
};

export const azureFunctions = {
  portalFunctions : {
    baseUrl: "https://func-app-jafarportal-test.azurewebsites.net/api",
    authCode: "StbknqR-KthSuf-BALG3C7EmySFjm5bqZ_TWphuAPqaFAzFurjgyWQ==",
  },
  approvalFunctions : {
    baseUrl: "https://func-app-jafarportal-approvalprocess-test.azurewebsites.net/api",
    authCode: "H7G1it00vNCnnT7t6-LUTcXABUochfcceHcrHG30PxLjAzFudhSvSw==",
  }
}

export const CIPSettings : CIPAPIConfig = {
  code            : "StbknqR-KthSuf-BALG3C7EmySFjm5bqZ_TWphuAPqaFAzFurjgyWQ==",
  baseUrl         : "https://func-app-jafarportal-test.azurewebsites.net/api",
  endPoints       : {
    changes             : "CIPChanges",
    changeDetails       : "CIPChangeDetails",
    incidents           : "CIPIncidents",
    incidentDetails     : "CIPIncidentDetails",
    requests            : "CIPRequests",
    requestDetails      : "CIPRequestDetails",
    requestTasks        : "CIPRequestTasks",
    requestTaskDetails  : "CIPRequestTaskDetails",
    envRoute            : "CIPEnvRoute",
    envRouteDefault     : "CIPEnvRouteDefault"
  }
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
