import { Injectable, Injector, inject } from "@angular/core";
import { BehaviorSubject, Observable, map } from "rxjs";
import { cipInjectionTokens } from "src/environments/InjectionTokens/cipInjectionTokens";
import { CIPProcessState, CIPItemState } from "../../interfaces/CIP/cip-states.interface";
import { CIPRequestTasksService } from "../../services/http-requests/azure-functions/cip/cip-requestTasks.service";
import { CIPHeader } from "../../interfaces/CIP/cip-page-layout.interface";
import { CIPRequestTask, CIPRequestTaskRecord } from "../../interfaces/CIP/cip-requestTasks.interface";
import { CIPQuestionRecord} from "../../interfaces/CIP/cip-questions.interface";
import { Customer } from "src/app/pages/manage-azure-app-reg/datastore/customer.interface";


@Injectable({
    providedIn: 'root'
})
export class CipRequestTaskStore {
  // Inject the service the store is linked too
  private requestTaskService = inject(CIPRequestTasksService)

  // Decalare the Behaviour Subjects to Store the Data we need
  private readonly _allRequestTasks$ : BehaviorSubject<CIPRequestTask[]>   = new BehaviorSubject<CIPRequestTask[]>([]);

  private readonly _emptyCIPRecord : CIPRequestTaskRecord= {
      requestTaskInfo : null,
      workLogs        : [],
      questions       : [],
      displayQuestions :[]
  }
  private readonly _requestTaskInfo$ : BehaviorSubject<CIPRequestTaskRecord>   = new BehaviorSubject<CIPRequestTaskRecord>(this._emptyCIPRecord);

  // Methods to populate the Stores

  fetchRequestTasks(search? : string){
    this.requestTaskService.getRequestTasks(search)
    .subscribe(response => this._allRequestTasks$.next(response))
  }

  fetchRequestTaskInfo(cipId : string){
    this.requestTaskService.getRequestTaskDetails(cipId)
    .subscribe(response => this._requestTaskInfo$.next(response))
  }

  fetchCustomerRequestTasks(customer: Customer, dateObject?: any){
    // Initialize customer search string
    const customerSearchString = `customerId eq '${customer.customerId}'`

    // Construct final search string depending on inputs
    const searchString = dateObject ?
    customerSearchString + `and CreatedAt ge ${dateObject.startDate} and CreatedAt le ${dateObject.endDate}`:
    customerSearchString

    this.requestTaskService.getRequestTasks(searchString).subscribe(response =>
      {
        console.log(response)
        this._allRequestTasks$.next(response)
      }
    )
  }

  resetRequestTaskInfo(){
    this._requestTaskInfo$.next(this._emptyCIPRecord)
  }

  validateRequestTasks(search? : string){
    return this.requestTaskService.getRequestTasks(search)
  }

  validateRequestTaskInfo(cipId : string){
    return this.requestTaskService.getRequestTaskDetails(cipId)
  }
  // Observable Streams of the contents of the stroe
  // REQUESTTASKS
  get requestTasks$(): Observable<CIPRequestTask[]> {
    return this._allRequestTasks$.asObservable();
  }

  get requestTaskInfo$(): Observable<CIPRequestTaskRecord> {
    return this._requestTaskInfo$.asObservable();
  }

  get requestTaskHeaderInjector() : Injector {
      const requestTaskHeader : Observable<CIPHeader | null> = this._requestTaskInfo$.pipe(
          map((requestTask : CIPRequestTaskRecord)=> {
            if (requestTask.requestTaskInfo) {
              const requestTaskInfo : CIPRequestTask = requestTask.requestTaskInfo
              const  header : CIPHeader = {
                  CIPType         : "RequestTask",
                  CIPInternalId   : requestTaskInfo.internalRequestTaskId,
                  Customer        : requestTaskInfo.msgHeaderCompany,
                  CreatedOn       : requestTaskInfo.createdAt,
                  ProcessState    : (requestTaskInfo.processStateName).toLowerCase() as CIPProcessState,
                  ItemState       : (requestTaskInfo.requestTaskStateName).toLowerCase() as CIPItemState
              }
              return header
            }
            else{
							return null
						}
          })
      )
      return Injector.create({providers: [{ provide: cipInjectionTokens.cipInformationToken, useValue: requestTaskHeader}]})
  }

  get requestTaskInjector() : Injector {
      const requestTaskInfo = this._requestTaskInfo$.pipe(map((result :any)=>( result.requestTaskInfo as CIPRequestTask )) )
      return Injector.create({providers: [{ provide: cipInjectionTokens.cipInformationToken, useValue: requestTaskInfo}]})
  }

  get requestTaskQuestionsInjector() : Injector {
      const questions = this._requestTaskInfo$.pipe(
        map(
          (result : CIPRequestTaskRecord )=>{
            let allQuestions : CIPQuestionRecord = {
              questions: result.questions ?? [],
              displayQuestions: result.displayQuestions ??[]
            }
            return allQuestions
          }
        )
      )
      return Injector.create({providers: [{ provide: cipInjectionTokens.questionsToken, useValue: questions}]})
  }

  get requestTaskWorkLogsInjector() : Injector {
      const worklogs = this._requestTaskInfo$.pipe(map((result)=>( result.workLogs ?? [])) )
      return Injector.create({providers: [{ provide: cipInjectionTokens.workLogsToken, useValue: worklogs}]})
  }
}

